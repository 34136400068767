<script>
import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
export default {
  mixins: [errorsMixin],
  props: {
    maxStudents: Number,
    ID: Number
  },
  
  render() {
    return this.$scopedSlots.default({
      showing: this.showing,
      toggle: this.toggle,
      formErrors: this.formErrors,
      checkValidatorBeforeSend: this.checkValidatorBeforeSend,
      updateShowing: this.updateShowing,
      formData: this.formData,
      send: this.send,
      count: this.count,
      updateCount: this.updateCount
    })
  },

  inject: ['svc'],

  data:() => ({
    showing: false,
    processing: false,
    count: 0
  }),

  methods: {
    toggle() {
      this.updateShowing(!this.showing)
    },

    updateShowing(val) {
      this.showing = val
      if(!val) return
      this.count = this.maxStudents
    },

    updateCount(val) {
      this.count = +val
    },

    async send() {
      try {
        this.processing = true
        let res = await this.svc.updateCourse(this.ID, {
          maxStudents: this.count
        })
        this.$emit('updated', this.count)
        this.$notify({text: 'Course updated', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>